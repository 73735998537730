import Vue from 'vue';
import Rollbar from 'vue-rollbar';

const isProduction = process.env.VUE_APP_ENV === 'production';

Vue.use(Rollbar, {
  accessToken: process.env.VUE_APP_ROLLBAR_TOKEN,
  hostSafeList: ['motionelements.com', 'cloudfront.net'],
  captureUncaught: !isProduction,
  captureUnhandledRejections: !isProduction,
  verbose: !isProduction,
  enabled: true, //! ['development'].includes(process.env.VUE_APP_ENV),
  environment: process.env.VUE_APP_ENV,
});

Vue.config.errorHandler = function (err, vm) {
  if (!isProduction) {
    // DON'T REMOVE THIS CONSOLE LOG
    console.log(err);
    vm.$rollbar.error(err);
  }
};
